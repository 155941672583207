<template>
  <v-app>
    <PcLogin v-if="mobileDevice === 'pcDevice'"></PcLogin>
    <MobileLogin v-if="mobileDevice === 'mobileDevice'"></MobileLogin>
  </v-app>
</template>

<script>
import { isMobile, isLark } from "@/util/util";
import PcLogin from "./component/PcLogin";
import MobileLogin from "./component/MobileLogin";

export default {
  name: "login",
  data() {
    return {
      mobileDevice: null
    };
  },
  async created() {
    if (await isMobile() || isLark()) {
      this.mobileDevice = "mobileDevice";
    } else {
      this.mobileDevice = "pcDevice";
    }
  },
  components: {
    PcLogin,
    MobileLogin
  }
};
</script>
