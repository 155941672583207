<template>
    <div>
      <v-form :model="form1" ref="form1" @submit.native.prevent>
        <v-form :model="form2" ref="form2" @submit.native.prevent>
          <v-text-field
            class="text-body-1"
            v-model="loginName"
            label="手机号"
            outlined
            required
            :rules="rules.isFull"
            hide-details="auto"
          ></v-text-field>
        </v-form>
        <div class="mt-2" style="position:relative;">
          <v-text-field
            v-model="verificationCode"
            class="text-body-1 d-flex flex-row align-center"
            :label="verifycodePlaceholder"
            outlined
            required
            :rules="rules.isRule"
            @keydown.enter="submitSMS(curTabItem, loginName, verificationCode)"
            maxlength="6"
          ></v-text-field>
          <v-btn
            depressed
            @click.stop="sendCode"
            class="white--text"
            :disabled="allTime < 60"
            style="z-index:0;position:absolute;top:1px;right:1px;border-radius:2px;width:100px;height:46px"
            :style="{
              backgroundColor: `${highlightColor}`
            }"
            >{{
              allTime === 60 || allTime === 0 ? "发送验证码" : allTime + "s"
            }}</v-btn
          >
        </div>
        <div class="d-flex flex-column">
          <v-btn
            depressed
            class="white--text text-body-1"
            :style="{
              backgroundColor: `${highlightColor}`,
              height: '48px'
            }"
            @click="submitSMS(curTabItem, loginName, verificationCode)"
          >
            {{ loginSubmit }}
          </v-btn>
        </div>
      </v-form>
    </div>
  </template>
  
  <script>
  import { api_request } from "@/util/network";
  import { encryptPassword } from "@/util/jwes";
  
  export default {
    name: "Webvpn",
    props: ["curTabItem", "sonData", "fromType"],
    data() {
      let isRule = value => {
        let reg = /^\d{6}$/;
        return value && reg.test(value);
      };
      return {
        rules: {
          isFull: [v => !!v || "此项为必填项"],
          isRule: [v => isRule(v) || "请输入正确的验证码"]
        },
        form1: {},
        form2: {},
        loginName: "",
        verificationCode: "",
        verifycodePlaceholder: "输入验证码",
        allTime: 60,
        highlightColor: "#FF0000",
        loginSubmit: "登录"
      };
    },
    created() {
      this.getData();
    },
    methods: {
      // 短信登录
      sendCode() {
        if (this.$refs.form2.validate()) {
          let timer = setInterval(() => {
            if (this.allTime > 0) {
              this.allTime = this.allTime - 1;
            } else {
              clearInterval(timer);
              this.allTime = 60;
            }
          }, 1000);
          return this.$http
            .get(
              `api/source/${this.curTabItem.id}/start?username=${this.loginName}`
            )
            .delegateTo(api_request)
            .then(data => {
              let {
                action: { mobile }
              } = data;
              this.challenge = data.challenge;
              this.exchange_key = data.exchange_key;
              return `已发送至${mobile}`;
            })
            .catch(({ code, message }) => {
              clearInterval(timer);
              this.allTime = 60;
              if (code === "MobileNotFound") {
                throw "此用户无关联手机号";
              } else if (message === "too frequently") {
                throw `发送验证码过于频繁，请等待一分钟后再试`;
              } else {
                throw `发送验证码失败：${this.$t("api." + code)}`;
              }
            })
            .delegateTo(this.$snackbar.delegate);
        }
      },
      submitSMS(curTabItem, loginName, verificationCode) {
        if (this.$refs.form1.validate()) {
          // 调取短信登录接口
          let loginUserNameKey = curTabItem.fields[0];
          let loginPwsKey = curTabItem.fields[1];
          let codeKey = curTabItem.fields[2];
          let credentials = {
            [loginUserNameKey]: loginName,
            [loginPwsKey]: "",
            [codeKey]: verificationCode
          };
  
          if (credentials["password"] !== undefined) {
            credentials["password"] = encryptPassword(
              this.exchange_key,
              this.challenge,
              credentials["password"]
            );
          }
          this.$http
            .post(`api/source/${curTabItem.id}/finish`, credentials)
            .delegateTo(api_request)
            .then(data => {
              if (this.fromType === "WECHAT_RZKC") {
                sessionStorage.removeItem("needBind");
                this.$emit("showAccountEvent", {
                  showAccount: true,
                  accountInfo: data
                });
              } else {
                this.$store.commit("auth_success", data);
                if (data["return_url"]) {
                  window.location = data["return_url"];
                } else {
                  this.$router.push({ path: "/" });
                }
                return "登录成功";
              }
            })
            .catch(({ code, message }) => {
              throw `登录失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
                "api." + typeof message === "string"
                  ? message
                  : JSON.stringify(message)
              )}`;
            })
            .delegateTo(this.$snackbar.delegate);
        }
      },
      getData() {
        if (this.sonData) {
          let {
            loginSubmit,
            verifycodePlaceholder,
            loginFrame: { loginHighLight }
          } = this.sonData;
          this.verifycodePlaceholder = verifycodePlaceholder
            ? verifycodePlaceholder
            : "输入验证码";
          this.highlightColor = loginHighLight;
          this.loginSubmit = loginSubmit ? loginSubmit : "登录";
        }
      }
    }
  };
  </script>
  
  <style></style>
  